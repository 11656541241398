import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Shared
import Image from 'components/shared/Image'

const PostThumbnail = styled(Image)`
  min-height: 270px;
  max-height: 270px;
  object-fit: cover;
`

const PostTitle = styled.span`
  font-size: ${({ theme }) => theme.font.size[20]};
  font-weight: ${({ theme }) => theme.font.weight.xl};
`

const Blog = ({ posts }) => {
  return (
    <div className="row">
      {posts.map(({ node }) => (
        <div
          key={node.wordpress_id}
          className="col-lg-4 col-md-6 d-flex justify-content-center mb-4"
        >
          <Post fields={node} />
        </div>
      ))}
    </div>
  )
}

const Post = ({ fields }) => (
  <div className="w-100">
    <Link to={fields.path}>
      <motion.div whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }}>
        <PostThumbnail src={fields.acf.overview.image} className="mb-2 w-100" />
      </motion.div>
    </Link>
    <Link to={fields.path} className="px-3">
      <PostTitle>{fields.title}</PostTitle>
    </Link>
  </div>
)

export default Blog
