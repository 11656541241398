import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

// Elements
import ImageBackground from 'components/shared/ImageBackground'
import Content from 'components/shared/Content'
import ButtonDefault from 'components/elements/ButtonDefault'
import ParseContent from 'components/shared/ParseContent'

// Blog
import Blog from 'components/related/Blog'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'

const Title = styled(Content)`
  text-overflow: ellipsis;
  overflow: hidden;

  & h2 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${({ theme }) => theme.font.size['40']};
    text-transform: uppercase;
  }
`

const PostTitle = styled(Content)`
  & h1 {
    font-size: ${(props) => props.theme.font.size['65']};
    text-transform: uppercase;
    @media (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size['40']};
    }
  }
`

const SortPost = styled(Content)`
  font-size: ${(props) => props.theme.font.size.xm};
  text-transform: uppercase;
`

const Header = styled.section`
  @media (min-width: 992px) {
    min-height: 540px;
  }
`

const Background = styled(ImageBackground)`
  min-height: 540px;
  width: 1030px;
  position: absolute !important;
  background-size: contain !important;
  right: 0;
  top: 0;
  @media (max-width: 991px) {
    max-height: 260px;
    min-height: 260px;
    width: 100% !important;
  }
`

const Container = styled.div`
  z-index: 2;
  @media (max-width: 991px) {
    margin-top: 18rem;
  }
`

const NewsTitle = styled(Content)`
  & h2 {
    font-size: ${(props) => props.theme.font.size['40']};
    & span:first-child {
      font-size: ${(props) => props.theme.font.size.l};
      font-style: italic;
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, acf, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout noChurch>
      <SEO yoast={yoast} path={path} type="article" />

      <Header className="position-relative d-flex align-items-center">
        <Container className="container position-relative">
          <div className="row py-lg-5 ">
            <div className="col-lg-8">
              <SortPost content={acf.banner.sort_post} />
              <PostTitle content={acf.banner.title} />
            </div>
          </div>
        </Container>
        <Background src={acf.banner.image} />
      </Header>

      <section>
        <div className="container">
          {acf.content.map(({ title, description }, index) => (
            <div className="row py-lg-5">
              <div className="col-lg-4">
                <Title content={title} />
              </div>
              <div className="col-lg-8">
                <Content content={description} />
              </div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <div className="container py-lg-5 my-5">
          <div className="row pb-5 align-items-center">
            <div className="col-sm-6 d-flex align-items-center justify-content-between">
              <NewsTitle content="<h2><span class='highlighted'>Meer</span><br>Nieuws</h2>" />
            </div>
            <div className="col-sm-6 d-flex justify-content-end">
              <ButtonDefault to="/nieuws">Nieuwsoverzicht</ButtonDefault>
            </div>
          </div>

          <Blogik
            settings={{
              limit: 3,
            }}
          >
            <BlogConsumer>
              {({ hasPosts, posts }) => {
                return (
                  <div>
                    {hasPosts && <Blog posts={posts} />}

                    {!hasPosts && <ParseContent content="Geen resultaten" />}
                  </div>
                )
              }}
            </BlogConsumer>
          </Blogik>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content

      acf {
        banner {
          image {
            localFile {
              publicURL
            }
          }
          sort_post
          title
        }

        content {
          title
          description
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
